import { GatsbySeo } from 'gatsby-plugin-next-seo'

import SacolasReutilizaveis from '../components/SacolasReutilizaveis'

function DecathlonItau() {
  return (
    <>
      <GatsbySeo title="Sacolas Reutilizáveis | Decathlon" />
      <SacolasReutilizaveis />
    </>
  )
}

export default DecathlonItau
